.Services {
  background-color: var(--background);
  background-repeat: no-repeat;
  background-position: center right; }
.Services-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px; }
.ServiceTile {
  width: 100%;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  border-left: 1px #D9D9D9 solid;
  align-content: start;
  .Avatar {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center center; } }

@media screen and ( max-width: 768px ) {
  .Services-Container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    .ServiceTile {
      &:last-child {
        grid-column: 1/-1; } } } }
@media screen and ( max-width: 576px ) {
  .Services-Container {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .ServiceTile {
      border-left: 0px;
      &:not(:last-child) {
        border-bottom: 1px #D9D9D9 solid; } } } }
