.About {
  width: 100%;
  background-position: center center;
  background-size: cover; }
.About-Container {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 64px;
  .LogoAbout {
    height: 72px; }
  .Avatar {
    width: 100%;
    height: 500px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      position: relative;
      z-index: 5; }
    &::after {
      content: '';
      display: block;
      width: 100%;
      left: -32px;
      bottom: -32px;
      height: 100%;
      background-color: rgba(0,0,0, 0.5);
      position: absolute; } } }

.About-Info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start;
  // background-color: rgba(0,0,0,0.5)
  padding: 24px;
  color: #fff;
  .Title {
    margin-bottom: 0;
    font-size: 48px;
    line-height: 48px;
    color: var(--accent); }
  .Description {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Description-Facts {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }
@media screen and ( max-width: 992px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .About-Info {
    .Title {
      font-size: 36px;
      line-height: 46px; } } }

@media screen and ( max-width: 576px ) {
  .About-Container {
    grid-gap: 48px;
    .Avatar {
      height: 280px;
      order: 0; }
    .About-Info {
      order: 1; } } }
