.Numbers {
  background-color: #FAF7F3; }
.Numbers-Container {
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 64px;
  align-content: start;
  .Info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .SectionTitle {
      justify-content: start;
      margin-bottom: 0; } } }
.NumbersCarousel {
  width: 450px;
  align-self: start;
  height: 500px;
  img {
    width: 100%;
    object-fit: cover;
    height: 500px;
    object-position: center center; } }
.Numbers-Data {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px;
  margin-top: 48px; }
.NumberInfo {
  padding-left: 32px;
  border-left: 1px #D9D9D9 solid;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-content: start;
  .Counter {
    font-size: 64px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'El Messiri', sans-serif; }
  .cTitle {
    font-size: 24px;
    text-transform: uppercase; }
  .Title {
    font-size: 18px;
    line-height: 28px;
    font-family: 'El Messiri', sans-serif;
    margin-top: -16px;
    font-weight: 700; }
  .OptionContainer {
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-gap: 24px; } }

@media screen and ( max-width: 1024px) {
  .Numbers-Container {
    grid-template-columns: 1fr; }
  .Slider {
    width: 100%; } }

@media screen and ( max-width: 576px ) {
  .NumbersCarousel {
    width: 340px;
    justify-self: center;
    height: 400px;
    img {
      height: 400px; } }
  .Numbers-Data {
    grid-template-columns: 1fr;
    grid-gap: 48px; }
  .NumberInfo {
    border-left: 0px;
    padding-bottom: 24px;
    &:first-child {
      border-bottom: 1px #D9D9D9 solid; } } }
