.Food-Container {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 80px;
  .Info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      font-size: 24px;
      font-weight: 700; }
    .Bolder {
      font-weight: 700; }
    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      list-style-type: disc;
      padding-left: 16px; } }
  .Food-Img {
    width: 450px;
    height: 500px;
    position: relative;
    img {
      width: 100%;
      height: 500px;
      object-fit: contain;
      object-position: center center;
      position: relative;
      z-index: 3;
      cursor: pointer; }
    .FoodCarousel {
      height: 500px; }
    // &::before
    //   content: ''
    //   display: block
    //   width: 100%
    //   height: 100%
    //   background-color: #E9E9DD
    //   position: absolute
    //   left: -32px
    //   bottom: -32px
 } }    //   z-index: 1

@media screen and ( max-width: 768px ) {
  .Food-Container {
    grid-template-columns: 1fr;
    .Food-Img {
      order: 0;
      width: 340px;
      height: 400px;
      img {
        object-fit: cover;
        height: 400px; }
      .FoodCarousel {
        height: 400px; } }
    .Info {
      order: 1; } } }
