.PhoneChooser {
  display: flex;
  align-items: center;
  .ChooserIcon {
    color: var(--accent2); }
  .Icon {
    font-size: 18px; }
  .PhoneChooser-ActivePhone {
    font-size: 16px;
    margin: 0px 8px 0px 12px;
    font-weight: 500;
    color: var(--black); } }
