.HotelBenefits {}

.HotelBenefits-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px; }
.Benefit {
  display: flex;
  // grid-template-columns: 42px 1fr
  // grid-gap: 16px
  align-content: center;
  .BIcon {
    width: 42px;
    height: 42px;
    background-color: var(--accent);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
    .Icon {
      color: #fff;
      font-size: 26px; } }
  .BContent {
    align-self: start; }
  .Title {
    font-size: 24px;
    line-height: 28px;
    font-family: 'El Messiri', sans-serif;
    font-weight: 700;
 } }    // margin-bottom: 16px

@media screen and ( max-width:  768px) {
  .HotelBenefits-Container {
    grid-gap: 24px; }
  .Benefit {
    .Title {
      font-size: 18px;
      line-height: 24px;
      font-family: 'El Messiri', sans-serif;
      font-weight: 700; } } }
@media screen and ( max-width: 576px ) {
  .HotelBenefits-Container {
    grid-template-columns: 1fr; } }
