.HeroText {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: -70px;
  width: 100%;
  height: 140px;
  background-size: contain;
  background-position: top 0px right 64px;
  background-repeat: no-repeat; }

.Stars {
  display: grid;
  grid-template-columns: repeat(3, 32px);
  grid-gap: 12px;
  justify-content: center;
  margin-bottom: 16px;
  .Star {
    display: block;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat; } }
.Site-Hero {
  width: 100%;
  height: 550px;
  padding: 0px 64px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: top -120px center;
  background-repeat: no-repeat;
  position: relative;
  .Offer {
    margin-top: 100px;
    // max-width: 600px
    // display: grid
    // grid-template-columns: 1fr
    text-align: center; }
  .Offer-Title {
    font-size: 32px;
    font-weight: 400;
    font-family: 'El Messiri', sans-serif;
    text-transform: uppercase;
    text-align: center;
    span {
      font-weight: 700;
      font-size: 52px;
      position: relative;
      line-height: 70px; } }
  .Button-Container {
    padding: 24px;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    max-width: 500px;
    margin-left: 50%;
    transform: translateX(-50%);
    .Title {
      color: #fff;
      font-size: 18px;
      font-family: 'El Messiri', sans-serif;
      margin-bottom: 24px; } } }

@media screen and ( max-width: 768px ) {
  .Site-Hero {
    background-position: top center; } }

@media screen and ( max-width: 576px ) {
  .Site-Hero {
    .Offer-Title {
      span {
        font-size: 36px;
        line-height: 44px; } } } }
